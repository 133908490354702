import {
	faBriefcase,
	faCalendar,
	faCheck,
	faCog,
	faEnvelope,
	faFileContract,
	faFileSignature,
	faHandshake,
	faMoneyBill,
	faPassport,
	faPause,
	faPen,
	faPhone,
	faPlus,
	faStar,
	faTimes,
	faUsers,
	faVideo,
} from '@fortawesome/pro-light-svg-icons';
import { ContractTypeKeys, WorkingPatternKeys } from '../../types';

export const jobDepartments = [
	'QA',
	'Recruitment',
	'Operations',
	'Marketing',
	'Development',
	'C-Level',
	'Finance',
	'Human Resources',
	'Facilities',
	'Investment',
	'Sales',
	'Procurement',
	'Information Technology',
	'Investor Relations',
	'Other',
	'Compliance',
	'Family Office',
	'Investment Advisory',
	'Legal',
	'Office for CIO',
	'Co-Investment, Real Estate',
	'Design',
	'Securities',
	'Production',
	'Retail',
	'Hospitality',
	'Winery',
].sort();

export const jobTypes = [
	'Contract',
	'Part Time',
	'Full Time',
	'Permanent',
	'Temporary',
];

export const countries = [
	{
		value: 'GB',
		label: 'United Kingdom',
	},
	{ value: 'AU', label: 'Australia' },
	{ value: 'CA', label: 'Canada' },
	{ value: 'NZ', label: 'New Zealand' },
	{ value: 'US', label: 'United States of America' },
	{ value: 'AF', label: 'Afghanistan' },
	{ value: 'AL', label: 'Albania' },
	{ value: 'DZ', label: 'Algeria' },
	{ value: 'AS', label: 'American Samoa' },
	{ value: 'AD', label: 'Andorra' },
	{ value: 'AO', label: 'Angola' },
	{ value: 'AI', label: 'Anguilla' },
	{ value: 'AQ', label: 'Antarctica' },
	{ value: 'AG', label: 'Antigua and Barbuda' },
	{ value: 'AR', label: 'Argentina' },
	{ value: 'AM', label: 'Armenia' },
	{ value: 'AW', label: 'Aruba' },
	{ value: 'AT', label: 'Austria' },
	{ value: 'AZ', label: 'Azerbaijan' },
	{ value: 'BS', label: 'Bahamas' },
	{ value: 'BH', label: 'Bahrain' },
	{ value: 'BD', label: 'Bangladesh' },
	{ value: 'BB', label: 'Barbados' },
	{ value: 'BY', label: 'Belarus' },
	{ value: 'BE', label: 'Belgium' },
	{ value: 'BZ', label: 'Belize' },
	{ value: 'BJ', label: 'Benin' },
	{ value: 'BM', label: 'Bermuda' },
	{ value: 'BT', label: 'Bhutan' },
	{ value: 'BO', label: 'Bolivia' },
	{ value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
	{ value: 'BA', label: 'Bosnia and Herzegovina' },
	{ value: 'BW', label: 'Botswana' },
	{ value: 'BV', label: 'Bouvet Island' },
	{ value: 'BR', label: 'Brazil' },
	{ value: 'IO', label: 'British Indian Ocean Territory' },
	{ value: 'BN', label: 'Brunei Darussalam' },
	{ value: 'BG', label: 'Bulgaria' },
	{ value: 'BF', label: 'Burkina Faso' },
	{ value: 'BI', label: 'Burundi' },
	{ value: 'CV', label: 'Cabo Verde' },
	{ value: 'KH', label: 'Cambodia' },
	{ value: 'CM', label: 'Cameroon' },
	{ value: 'KY', label: 'Cayman Islands' },
	{ value: 'CF', label: 'Central African Republic' },
	{ value: 'TD', label: 'Chad' },
	{ value: 'CL', label: 'Chile' },
	{ value: 'CN', label: 'China' },
	{ value: 'CX', label: 'Christmas Island' },
	{ value: 'CC', label: 'Cocos (Keeling) Islands' },
	{ value: 'CO', label: 'Colombia' },
	{ value: 'KM', label: 'Comoros' },
	{ value: 'CD', label: 'Congo (the Democratic Republic of the)' },
	{ value: 'CG', label: 'Congo' },
	{ value: 'CK', label: 'Cook Islands' },
	{ value: 'CR', label: 'Costa Rica' },
	{ value: 'HR', label: 'Croatia' },
	{ value: 'CU', label: 'Cuba' },
	{ value: 'CW', label: 'Curaçao' },
	{ value: 'CY', label: 'Cyprus' },
	{ value: 'CZ', label: 'Czechia' },
	{ value: 'CI', label: "Côte d'Ivoire" },
	{ value: 'DK', label: 'Denmark' },
	{ value: 'DJ', label: 'Djibouti' },
	{ value: 'DM', label: 'Dominica' },
	{ value: 'DO', label: 'Dominican Republic' },
	{ value: 'EC', label: 'Ecuador' },
	{ value: 'EG', label: 'Egypt' },
	{ value: 'SV', label: 'El Salvador' },
	{ value: 'GQ', label: 'Equatorial Guinea' },
	{ value: 'ER', label: 'Eritrea' },
	{ value: 'EE', label: 'Estonia' },
	{ value: 'SZ', label: 'Eswatini' },
	{ value: 'ET', label: 'Ethiopia' },
	{ value: 'FK', label: 'Falkland Islands' },
	{ value: 'FO', label: 'Faroe Islands' },
	{ value: 'FJ', label: 'Fiji' },
	{ value: 'FI', label: 'Finland' },
	{ value: 'FR', label: 'France' },
	{ value: 'GF', label: 'French Guiana' },
	{ value: 'PF', label: 'French Polynesia' },
	{ value: 'TF', label: 'French Southern Territories' },
	{ value: 'GA', label: 'Gabon' },
	{ value: 'GM', label: 'Gambia' },
	{ value: 'GE', label: 'Georgia' },
	{ value: 'DE', label: 'Germany' },
	{ value: 'GH', label: 'Ghana' },
	{ value: 'GI', label: 'Gibraltar' },
	{ value: 'GR', label: 'Greece' },
	{ value: 'GL', label: 'Greenland' },
	{ value: 'GD', label: 'Grenada' },
	{ value: 'GP', label: 'Guadeloupe' },
	{ value: 'GU', label: 'Guam' },
	{ value: 'GT', label: 'Guatemala' },
	{ value: 'GG', label: 'Guernsey' },
	{ value: 'GN', label: 'Guinea' },
	{ value: 'GW', label: 'Guinea-Bissau' },
	{ value: 'GY', label: 'Guyana' },
	{ value: 'HT', label: 'Haiti' },
	{ value: 'HM', label: 'Heard Island and McDonald Islands' },
	{ value: 'VA', label: 'Holy See' },
	{ value: 'HN', label: 'Honduras' },
	{ value: 'HK', label: 'Hong Kong' },
	{ value: 'HU', label: 'Hungary' },
	{ value: 'IS', label: 'Iceland' },
	{ value: 'IN', label: 'India' },
	{ value: 'ID', label: 'Indonesia' },
	{ value: 'IR', label: 'Iran' },
	{ value: 'IQ', label: 'Iraq' },
	{ value: 'IE', label: 'Ireland' },
	{ value: 'IM', label: 'Isle of Man' },
	{ value: 'IL', label: 'Israel' },
	{ value: 'IT', label: 'Italy' },
	{ value: 'JM', label: 'Jamaica' },
	{ value: 'JP', label: 'Japan' },
	{ value: 'JE', label: 'Jersey' },
	{ value: 'JO', label: 'Jordan' },
	{ value: 'KZ', label: 'Kazakhstan' },
	{ value: 'KE', label: 'Kenya' },
	{ value: 'KI', label: 'Kiribati' },
	{ value: 'KP', label: "The Democratic People's Republic of Korea" },
	{ value: 'KR', label: 'Republic of Korea' },
	{ value: 'KW', label: 'Kuwait' },
	{ value: 'KG', label: 'Kyrgyzstan' },
	{ value: 'LA', label: "Lao People's Democratic Republic" },
	{ value: 'LV', label: 'Latvia' },
	{ value: 'LB', label: 'Lebanon' },
	{ value: 'LS', label: 'Lesotho' },
	{ value: 'LR', label: 'Liberia' },
	{ value: 'LY', label: 'Libya' },
	{ value: 'LI', label: 'Liechtenstein' },
	{ value: 'LT', label: 'Lithuania' },
	{ value: 'LU', label: 'Luxembourg' },
	{ value: 'MO', label: 'Macao' },
	{ value: 'MG', label: 'Madagascar' },
	{ value: 'MW', label: 'Malawi' },
	{ value: 'MY', label: 'Malaysia' },
	{ value: 'MV', label: 'Maldives' },
	{ value: 'ML', label: 'Mali' },
	{ value: 'MT', label: 'Malta' },
	{ value: 'MH', label: 'Marshall Islands' },
	{ value: 'MQ', label: 'Martinique' },
	{ value: 'MR', label: 'Mauritania' },
	{ value: 'MU', label: 'Mauritius' },
	{ value: 'YT', label: 'Mayotte' },
	{ value: 'MX', label: 'Mexico' },
	{ value: 'FM', label: 'Micronesia' },
	{ value: 'MD', label: 'Moldova' },
	{ value: 'MC', label: 'Monaco' },
	{ value: 'MN', label: 'Mongolia' },
	{ value: 'ME', label: 'Montenegro' },
	{ value: 'MS', label: 'Montserrat' },
	{ value: 'MA', label: 'Morocco' },
	{ value: 'MZ', label: 'Mozambique' },
	{ value: 'MM', label: 'Myanmar' },
	{ value: 'NA', label: 'Namibia' },
	{ value: 'NR', label: 'Nauru' },
	{ value: 'NP', label: 'Nepal' },
	{ value: 'NL', label: 'Netherlands' },
	{ value: 'NC', label: 'New Caledonia' },
	{ value: 'NI', label: 'Nicaragua' },
	{ value: 'NE', label: 'Niger' },
	{ value: 'NG', label: 'Nigeria' },
	{ value: 'NU', label: 'Niue' },
	{ value: 'NF', label: 'Norfolk Island' },
	{ value: 'MP', label: 'Northern Mariana Islands' },
	{ value: 'NO', label: 'Norway' },
	{ value: 'OM', label: 'Oman' },
	{ value: 'PK', label: 'Pakistan' },
	{ value: 'PW', label: 'Palau' },
	{ value: 'PS', label: 'Palestine, State of' },
	{ value: 'PA', label: 'Panama' },
	{ value: 'PG', label: 'Papua New Guinea' },
	{ value: 'PY', label: 'Paraguay' },
	{ value: 'PE', label: 'Peru' },
	{ value: 'PH', label: 'Philippines' },
	{ value: 'PN', label: 'Pitcairn' },
	{ value: 'PL', label: 'Poland' },
	{ value: 'PT', label: 'Portugal' },
	{ value: 'PR', label: 'Puerto Rico' },
	{ value: 'QA', label: 'Qatar' },
	{ value: 'MK', label: 'Republic of North Macedonia' },
	{ value: 'RO', label: 'Romania' },
	{ value: 'RU', label: 'Russian Federation' },
	{ value: 'RW', label: 'Rwanda' },
	{ value: 'RE', label: 'Réunion' },
	{ value: 'BL', label: 'Saint Barthélemy' },
	{ value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ value: 'KN', label: 'Saint Kitts and Nevis' },
	{ value: 'LC', label: 'Saint Lucia' },
	{ value: 'MF', label: 'Saint Martin' },
	{ value: 'PM', label: 'Saint Pierre and Miquelon' },
	{ value: 'VC', label: 'Saint Vincent and the Grenadines' },
	{ value: 'WS', label: 'Samoa' },
	{ value: 'SM', label: 'San Marino' },
	{ value: 'ST', label: 'Sao Tome and Principe' },
	{ value: 'SA', label: 'Saudi Arabia' },
	{ value: 'SN', label: 'Senegal' },
	{ value: 'RS', label: 'Serbia' },
	{ value: 'SC', label: 'Seychelles' },
	{ value: 'SL', label: 'Sierra Leone' },
	{ value: 'SG', label: 'Singapore' },
	{ value: 'SX', label: 'Sint Maarten' },
	{ value: 'SK', label: 'Slovakia' },
	{ value: 'SI', label: 'Slovenia' },
	{ value: 'SB', label: 'Solomon Islands' },
	{ value: 'SO', label: 'Somalia' },
	{ value: 'ZA', label: 'South Africa' },
	{ value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
	{ value: 'SS', label: 'South Sudan' },
	{ value: 'ES', label: 'Spain' },
	{ value: 'LK', label: 'Sri Lanka' },
	{ value: 'SD', label: 'Sudan' },
	{ value: 'SR', label: 'Surinamer' },
	{ value: 'SJ', label: 'Svalbard and Jan Mayen' },
	{ value: 'SE', label: 'Sweden' },
	{ value: 'CH', label: 'Switzerland' },
	{ value: 'SY', label: 'Syrian Arab Republic' },
	{ value: 'TW', label: 'Taiwan' },
	{ value: 'TJ', label: 'Tajikistan' },
	{ value: 'TZ', label: 'Tanzania, United Republic of' },
	{ value: 'TH', label: 'Thailand' },
	{ value: 'TL', label: 'Timor-Leste' },
	{ value: 'TG', label: 'Togo' },
	{ value: 'TK', label: 'Tokelau' },
	{ value: 'TO', label: 'Tonga' },
	{ value: 'TT', label: 'Trinidad and Tobago' },
	{ value: 'TN', label: 'Tunisia' },
	{ value: 'TR', label: 'Turkey' },
	{ value: 'TM', label: 'Turkmenistan' },
	{ value: 'TC', label: 'Turks and Caicos Islands' },
	{ value: 'TV', label: 'Tuvalu' },
	{ value: 'UG', label: 'Uganda' },
	{ value: 'UA', label: 'Ukraine' },
	{ value: 'AE', label: 'United Arab Emirates' },
	{ value: 'UM', label: 'United States Minor Outlying Islands' },
	{ value: 'UY', label: 'Uruguay' },
	{ value: 'UZ', label: 'Uzbekistan' },
	{ value: 'VU', label: 'Vanuatu' },
	{ value: 'VE', label: 'Venezuela (Bolivarian Republic of)' },
	{ value: 'VN', label: 'Viet Nam' },
	{ value: 'VG', label: 'Virgin Islands (British)' },
	{ value: 'VI', label: 'Virgin Islands (U.S.)' },
	{ value: 'WF', label: 'Wallis and Futuna' },
	{ value: 'EH', label: 'Western Sahara' },
	{ value: 'YE', label: 'Yemen' },
	{ value: 'ZM', label: 'Zambia' },
	{ value: 'ZW', label: 'Zimbabwe' },
	{ value: 'AX', label: 'Åland Islands' },
];

export const genders = [
	'Male',
	'Female',
	'Non-binary',
	'Transgender',
	'Intersex',
	'Prefer not to say',
];

export const currencies: { [key in string]: string } = {
	AED: 'United Arab Emirates Dirham',
	AFN: 'Afghan Afghani',
	ALL: 'Albanian Lek',
	AMD: 'Armenian Dram',
	ANG: 'Netherlands Antillean Guilder',
	AOA: 'Angolan Kwanza',
	ARS: 'Argentine Peso',
	AUD: 'Australian Dollar',
	AWG: 'Aruban Florin',
	AZN: 'Azerbaijani Manat',
	BAM: 'Bosnia-Herzegovina Convertible Mark',
	BBD: 'Barbadian Dollar',
	BDT: 'Bangladeshi Taka',
	BGN: 'Bulgarian Lev',
	BHD: 'Bahraini Dinar',
	BIF: 'Burundian Franc',
	BMD: 'Bermudan Dollar',
	BND: 'Brunei Dollar',
	BOB: 'Bolivian Boliviano',
	BRL: 'Brazilian Real',
	BSD: 'Bahamian Dollar',
	BTC: 'Bitcoin',
	BTN: 'Bhutanese Ngultrum',
	BWP: 'Botswanan Pula',
	BYN: 'Belarusian Ruble',
	BZD: 'Belize Dollar',
	CAD: 'Canadian Dollar',
	CDF: 'Congolese Franc',
	CHF: 'Swiss Franc',
	CLF: 'Chilean Unit of Account (UF)',
	CLP: 'Chilean Peso',
	CNH: 'Chinese Yuan (Offshore)',
	CNY: 'Chinese Yuan',
	COP: 'Colombian Peso',
	CRC: 'Costa Rican Colón',
	CUC: 'Cuban Convertible Peso',
	CUP: 'Cuban Peso',
	CVE: 'Cape Verdean Escudo',
	CZK: 'Czech Republic Koruna',
	DJF: 'Djiboutian Franc',
	DKK: 'Danish Krone',
	DOP: 'Dominican Peso',
	DZD: 'Algerian Dinar',
	EGP: 'Egyptian Pound',
	ERN: 'Eritrean Nakfa',
	ETB: 'Ethiopian Birr',
	EUR: 'Euro',
	FJD: 'Fijian Dollar',
	FKP: 'Falkland Islands Pound',
	GBP: 'British Pound Sterling',
	GEL: 'Georgian Lari',
	GGP: 'Guernsey Pound',
	GHS: 'Ghanaian Cedi',
	GIP: 'Gibraltar Pound',
	GMD: 'Gambian Dalasi',
	GNF: 'Guinean Franc',
	GTQ: 'Guatemalan Quetzal',
	GYD: 'Guyanaese Dollar',
	HKD: 'Hong Kong Dollar',
	HNL: 'Honduran Lempira',
	HRK: 'Croatian Kuna',
	HTG: 'Haitian Gourde',
	HUF: 'Hungarian Forint',
	IDR: 'Indonesian Rupiah',
	ILS: 'Israeli New Sheqel',
	IMP: 'Manx pound',
	INR: 'Indian Rupee',
	IQD: 'Iraqi Dinar',
	IRR: 'Iranian Rial',
	ISK: 'Icelandic Króna',
	JEP: 'Jersey Pound',
	JMD: 'Jamaican Dollar',
	JOD: 'Jordanian Dinar',
	JPY: 'Japanese Yen',
	KES: 'Kenyan Shilling',
	KGS: 'Kyrgystani Som',
	KHR: 'Cambodian Riel',
	KMF: 'Comorian Franc',
	KPW: 'North Korean Won',
	KRW: 'South Korean Won',
	KWD: 'Kuwaiti Dinar',
	KYD: 'Cayman Islands Dollar',
	KZT: 'Kazakhstani Tenge',
	LAK: 'Laotian Kip',
	LBP: 'Lebanese Pound',
	LKR: 'Sri Lankan Rupee',
	LRD: 'Liberian Dollar',
	LSL: 'Lesotho Loti',
	LYD: 'Libyan Dinar',
	MAD: 'Moroccan Dirham',
	MDL: 'Moldovan Leu',
	MGA: 'Malagasy Ariary',
	MKD: 'Macedonian Denar',
	MMK: 'Myanma Kyat',
	MNT: 'Mongolian Tugrik',
	MOP: 'Macanese Pataca',
	MRU: 'Mauritanian Ouguiya',
	MUR: 'Mauritian Rupee',
	MVR: 'Maldivian Rufiyaa',
	MWK: 'Malawian Kwacha',
	MXN: 'Mexican Peso',
	MYR: 'Malaysian Ringgit',
	MZN: 'Mozambican Metical',
	NAD: 'Namibian Dollar',
	NGN: 'Nigerian Naira',
	NIO: 'Nicaraguan Córdoba',
	NOK: 'Norwegian Krone',
	NPR: 'Nepalese Rupee',
	NZD: 'New Zealand Dollar',
	OMR: 'Omani Rial',
	PAB: 'Panamanian Balboa',
	PEN: 'Peruvian Nuevo Sol',
	PGK: 'Papua New Guinean Kina',
	PHP: 'Philippine Peso',
	PKR: 'Pakistani Rupee',
	PLN: 'Polish Zloty',
	PYG: 'Paraguayan Guarani',
	QAR: 'Qatari Rial',
	RON: 'Romanian Leu',
	RSD: 'Serbian Dinar',
	RUB: 'Russian Ruble',
	RWF: 'Rwandan Franc',
	SAR: 'Saudi Riyal',
	SBD: 'Solomon Islands Dollar',
	SCR: 'Seychellois Rupee',
	SDG: 'Sudanese Pound',
	SEK: 'Swedish Krona',
	SGD: 'Singapore Dollar',
	SHP: 'Saint Helena Pound',
	SLL: 'Sierra Leonean Leone',
	SOS: 'Somali Shilling',
	SRD: 'Surinamese Dollar',
	SSP: 'South Sudanese Pound',
	STD: 'São Tomé and Príncipe Dobra (pre-2018)',
	STN: 'São Tomé and Príncipe Dobra',
	SVC: 'Salvadoran Colón',
	SYP: 'Syrian Pound',
	SZL: 'Swazi Lilangeni',
	THB: 'Thai Baht',
	TJS: 'Tajikistani Somoni',
	TMT: 'Turkmenistani Manat',
	TND: 'Tunisian Dinar',
	TOP: "Tongan Pa'anga",
	TRY: 'Turkish Lira',
	TTD: 'Trinidad and Tobago Dollar',
	TWD: 'New Taiwan Dollar',
	TZS: 'Tanzanian Shilling',
	UAH: 'Ukrainian Hryvnia',
	UGX: 'Ugandan Shilling',
	USD: 'United States Dollar',
	UYU: 'Uruguayan Peso',
	UZS: 'Uzbekistan Som',
	VEF: 'Venezuelan Bolívar Fuerte (Old)',
	VES: 'Venezuelan Bolívar Soberano',
	VND: 'Vietnamese Dong',
	VUV: 'Vanuatu Vatu',
	WST: 'Samoan Tala',
	XAF: 'CFA Franc BEAC',
	XAG: 'Silver Ounce',
	XAU: 'Gold Ounce',
	XCD: 'East Caribbean Dollar',
	XDR: 'Special Drawing Rights',
	XOF: 'CFA Franc BCEAO',
	XPD: 'Palladium Ounce',
	XPF: 'CFP Franc',
	XPT: 'Platinum Ounce',
	YER: 'Yemeni Rial',
	ZAR: 'South African Rand',
	ZMW: 'Zambian Kwacha',
	ZWL: 'Zimbabwean Dollar',
};

export const currencyOptions = Object.keys(currencies).map(key => {
	const currencyKey = currencies[key];
	return { label: `${key} - ${currencyKey}`, value: key };
});

export const availableIcons = {
	Tick: faCheck,
	Star: faStar,
	Money: faMoneyBill,
	Briefcase: faBriefcase,
	Cross: faTimes,
	People: faUsers,
	Cog: faCog,
	New: faPlus,
	Handshake: faHandshake,
	Phone: faPhone,
	Video: faVideo,
	Passport: faPassport,
	Contract: faFileContract,
	Signature: faFileSignature,
	Pause: faPause,
	Calendar: faCalendar,
	Envelope: faEnvelope,
	Pen: faPen,
};

export const remoteWorkingLabels = {
	NONE: 'On site / office-based',
	PARTIAL: 'Hybrid',
	FULL: 'Fully remote',
};

export const workingPatternLabels = {
	FULL_TIME: 'Full time',
	PART_TIME: 'Part time',
	TERM_TIME: 'Term time',
	SHIFTS: 'Shifts',
	OTHER: 'Other',
};

export const contractTypeLabels = {
	PERMANENT: 'Permanent',
	FIXED_TERM: 'Fixed term',
	SHORT_TERM: 'Short term',
	SEASONAL: 'Seasonal',
	CONTRACT: 'Contract',
	OTHER: 'Other',
};

export const industries = [
	'Accountancy',
	'Accountancy (Qualified)',
	'Admin & Secretarial',
	'Advertising & PR',
	'Aerospace',
	'Agriculture Fishing & Forestry',
	'Arts',
	'Automotive',
	'Banking',
	'Building & Construction',
	'Call Centre & Customer Service',
	'Charity & Voluntary',
	'Consultancy',
	'Defence & Military',
	'Design & Creative',
	'Education & Training',
	'Electronics',
	'Energy & Renewables',
	'Engineering',
	'Executive & Management',
	'Fashion',
	'Financial Services',
	'FMCG',
	'Graduate Roles',
	'Health & Safety',
	'Hospitality & Catering',
	'HR & Personnel',
	'Insurance',
	'Internet & New Media',
	'IT',
	'Legal',
	'Leisure & Sport',
	'Logistics Distribution & Supply Chain',
	'Manufacturing & Production',
	'Marketing',
	'Media',
	'Medical & Nursing',
	'Other',
	'Pharmaceuticals',
	'Property & Housing',
	'Public Sector & Government',
	'Purchasing & Procurement',
	'Recruitment Consultancy',
	'Retail',
	'Sales',
	'Science & Research',
	'Social Care',
	'Telecommunications',
	'Transport & Rail',
	'Travel & Tourism',
	'Utilities',
];

export const contractTypes: { [key in ContractTypeKeys]: string } = {
	PERMANENT: 'Permanent',
	FIXED_TERM: 'Fixed term',
	SHORT_TERM: 'Short term',
	SEASONAL: 'Seasonal',
	CONTRACT: 'Contract',
	OTHER: 'Other',
};

export const workingPatterns: { [key in WorkingPatternKeys]: string } = {
	FULL_TIME: 'Full time',
	PART_TIME: 'Part time',
	TERM_TIME: 'Term time',
	SHIFTS: 'Shifts',
	OTHER: 'Other',
};
